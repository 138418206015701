<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="作业类型" prop="work_type" style="width: 25%">
              <a-select v-model="queryParams.work_type">
                <a-select-option value="清洁">清洁</a-select-option>
                <a-select-option value="绿化">绿化</a-select-option>
                <a-select-option value="消杀">消杀</a-select-option>
                <a-select-option value="白蚁防治">白蚁防治</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="作业子类型" prop="work_child_type" style="width: 25%">
              <a-select v-model="queryParams.work_child_type">
                <a-select-option v-if="queryParams.work_type==='清洁'" value="保洁">保洁</a-select-option>
                <a-select-option v-if="queryParams.work_type==='清洁'" value="清扫">清扫</a-select-option>
                <a-select-option v-if="queryParams.work_type==='清洁'" value="大做">大做</a-select-option>
                <a-select-option v-if="queryParams.work_type==='清洁'" value="高空">高空</a-select-option>
                <a-select-option v-if="queryParams.work_type==='清洁'" value="专项清洁">专项清洁</a-select-option>
                <a-select-option v-if="queryParams.work_type==='清洁'" value="垃圾处理">垃圾处理</a-select-option>
                <a-select-option v-if="queryParams.work_type==='绿化'" value="养护">养护</a-select-option>
                <a-select-option v-if="queryParams.work_type==='绿化'" value="病虫害防治">病虫害防治</a-select-option>
                <a-select-option v-if="queryParams.work_type==='绿化'" value="补种">补种</a-select-option>
                <a-select-option v-if="queryParams.work_type==='绿化'" value="加固">加固</a-select-option>
                <a-select-option v-if="queryParams.work_type==='消杀'" value="灭鼠">灭鼠</a-select-option>
                <a-select-option v-if="queryParams.work_type==='消杀'" value="灭蚊蝇">灭蚊蝇</a-select-option>
                <a-select-option v-if="queryParams.work_type==='消杀'" value="灭蟑">灭蟑</a-select-option>
                <a-select-option v-if="queryParams.work_type==='消杀'" value="消毒">消毒</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="任务名称" prop="task_name" style="width: 25%">
                <a-select v-model="queryParams.task_name" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in taskNameList" :key="index" :value="item.dicvalue">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="计划状态" prop="plan_status" style="width: 25%">
                <a-select v-model="queryParams.plan_status">
                  <a-select-option value="1">运行</a-select-option>
                  <a-select-option value="0">停止</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="作业点名称" prop="point_name" style="width: 25%">
                <a-input v-model="queryParams.point_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="作业单位" prop="unit_name" style="width: 25%">
                <a-input v-model="queryParams.unit_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="计划年份" prop="year" style="width: 25%">
                <a-select v-model="queryParams.year">
                  <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('复制')" @click="yearPlanCopy" style="margin-right: 10px"><a-icon type="snippets"></a-icon>年度计划复制</a-button>
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="plan_id" :loading="tableLoading" :scroll="{ x: 2800}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="plan_status" slot-scope="value">
            <a-tag :color="value==1?'green':'red'">{{value==1?'运行':'停止'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.plan_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.plan_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('复制')" :key="'copy-'+record.plan_id">复制</a-menu-item>
                <a-menu-item v-if="btnList.includes('粘贴')" :key="'paste-'+record.plan_id">粘贴</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.plan_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="planModalVisible" title="年度计划复制" :width="400" :centered="true" @cancel="planModalVisible=false">
      <template slot="footer">
        <a-button @click="planModalVisible=false">关闭</a-button>
        <a-button @click="generateYearPlan" type="primary">保存</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划来源年份" prop="sourceYear">
          <a-select v-model="formData.sourceYear" placeholder="请选择">
            <a-select-option :value="moment().year()-1">{{moment().year()-1}}</a-select-option>
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="目标年份" prop="targetYear">
          <a-select v-model="formData.targetYear" placeholder="请选择">
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
            <a-select-option :value="moment().year()+1">{{moment().year()+1}}</a-select-option>
            <a-select-option :value="moment().year()+2">{{moment().year()+2}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData(false)"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/environment/workPlan/addOrEditModal";
import {
  deleteWorkPlan, exportWorkPlanExcel, generateYearPlan, getWorkUnitsList,
  getWorkPlanListByCondition,
} from "A/businessmanagement";
import planExecuteBoard from "V/facilityAndEquipment/maintenanceManagement/maintenance/plan/planExecuteBoard";
import {getMonitorPointNameListByCondition} from "A/monitoring";
export default {
  name: "workPlan",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        task_name:'',
        contract_num:'',
        point_name:'',
        unit_name:'',
        year:'',
        work_type:'',
        work_child_type:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '作业类型',
          dataIndex: 'work_type',
          key: 'work_type',
          ellipsis: true,
        },
        {
          title: '作业子类型',
          dataIndex: 'work_child_type',
          key: 'fire_compartment',
          ellipsis: true,
        },
        {
          title: '作业单位',
          dataIndex: 'unit_name',
          key: 'unit_name',
          ellipsis: true,
        },
        {
          title: '楼层',
          dataIndex: 'floor',
          key: 'floor',
          ellipsis: true,
        },
        {
          title: '区域',
          dataIndex: 'region',
          key: 'region',
          ellipsis: true,
        },
        {
          title: '作业内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '作业点名称',
          dataIndex: 'location_name',
          key: 'location_name',
          ellipsis: true,
        },
        {
          title: '频次',
          dataIndex: 'frequency',
          key: 'frequency',
          ellipsis: true,
        },
        {
          title: '岗位名称',
          dataIndex: 'post_name',
          key: 'post_name',
          ellipsis: true,
        },
        {
          title: '当前状态',
          dataIndex: 'plan_status',
          key: 'plan_status',
          ellipsis: true,
          width: 75,
          scopedSlots: { customRender: 'plan_status' },
        },
        {
          title: '一月',
          dataIndex: 'month_one',
          key: 'month_one',
          ellipsis: true,
        },
        {
          title: '二月',
          dataIndex: 'month_two',
          key: 'month_two',
          ellipsis: true,
        },
        {
          title: '三月',
          dataIndex: 'month_three',
          key: 'month_three',
          ellipsis: true,
        },
        {
          title: '四月',
          dataIndex: 'month_four',
          key: 'month_four',
          ellipsis: true,
        },
        {
          title: '五月',
          dataIndex: 'month_five',
          key: 'month_five',
          ellipsis: true,
        },
        {
          title: '六月',
          dataIndex: 'month_six',
          key: 'month_six',
          ellipsis: true,
        },
        {
          title: '七月',
          dataIndex: 'month_seven',
          key: 'month_seven',
          ellipsis: true,
        },
        {
          title: '八月',
          dataIndex: 'month_eight',
          key: 'month_eight',
          ellipsis: true,
        },
        {
          title: '九月',
          dataIndex: 'month_nine',
          key: 'month_nine',
          ellipsis: true,
        },
        {
          title: '十月',
          dataIndex: 'month_ten',
          key: 'month_ten',
          ellipsis: true,
        },
        {
          title: '十一月',
          dataIndex: 'month_eleven',
          key: 'month_eleven',
          ellipsis: true,
        },
        {
          title: '十二月',
          dataIndex: 'month_twelve',
          key: 'month_twelve',
          ellipsis: true,
        },
        {
          title: '计划年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      taskNameList:[],
      userdepidCascaderSelected: [],
      copyData:{},
      yearOptions: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      monitorpointList:[],
      formData:{
        monitorpointnum:'',
        sourceYear:'',
        targetYear:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        sourceYear: [{required: true, message: '请选择计划来源年份'}],
        targetYear: [{required: true, message: '请选择目标年份'}],
      },
      planModalVisible: false,
      planModalShowType: '',
      planModalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "作业计划"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"环境管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMonitorPointNameList()
    this.getTableData()
    this.getQueryCondition();
    this.initDeptOptionsAll();
  },
  methods:{
    getQueryCondition(){
      getWorkUnitsList({}).then(res=>{
        if(res && res.returncode == '0') {
          this.taskNameList=res.item
        }
      })
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getWorkPlanListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let plan_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'plan_id', plan_id);
      if(type == 'delete') {
        this.deleteConfirm(plan_id, record)
      }else if(type == 'copy'){
        this.copyData=record
        this.$message.success("数据已经复制完成")
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type == 'paste'){
        if(this.copyData.month_one){
          this.modalDetailData=record
          this.modalDetailData.plan_status=1
          this.modalDetailData.month_one=this.copyData.month_one
          this.modalDetailData.month_two=this.copyData.month_two
          this.modalDetailData.month_three=this.copyData.month_three
          this.modalDetailData.month_four=this.copyData.month_four
          this.modalDetailData.month_five=this.copyData.month_five
          this.modalDetailData.month_six=this.copyData.month_six
          this.modalDetailData.month_seven=this.copyData.month_seven
          this.modalDetailData.month_eight=this.copyData.month_eight
          this.modalDetailData.month_nine=this.copyData.month_nine
          this.modalDetailData.month_ten=this.copyData.month_ten
          this.modalDetailData.month_eleven=this.copyData.month_eleven
          this.modalDetailData.month_twelve=this.copyData.month_twelve
          this.modalShowType=type
          this.modalVisible=true
        }else{
          this.$message.warning("您未复制任何数据，请您复制后再进行粘贴操作")
        }
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    planModal(){
      this.planModalVisible=true
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportWorkPlanExcel()
      }).catch(()=>{
      });
    },
    exportWorkPlanExcel(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportWorkPlanExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.plan_id);
      }).catch(()=>{

      }) ;
    },
    delete(plan_id) {
      this.showLoading();
      if(plan_id) {
        let params = {
          plan_id
        };
        deleteWorkPlan(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
    },
    yearPlanCopy(){
      this.planModalVisible=true
    },
    generateYearPlan(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          generateYearPlan({...this.formData})
          this.planModalVisible=false
          this.$message.success("内容已提交后台，正在处理中，请稍后再进行查看")
          this.formData={
            monitorpointnum:'',
            sourceYear:'',
            targetYear:'',
          }
        }
      })
    },
  },
}
</script>
<style scoped>

</style>